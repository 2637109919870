<template>
  <v-app-bar color="background" elevate-on-scroll app>
    <v-app-bar-nav-icon @click="Tdrawer()"></v-app-bar-nav-icon>

    <v-toolbar-title>
      <h3>E-Career Development</h3>
    </v-toolbar-title>
  </v-app-bar>
</template>

<script>
import Sdrawer from "@/store/drawer";

export default {
  data: () => ({}),

  async mounted() {},

  methods: {
    Tdrawer() {
      Sdrawer.commit("toggle", !Sdrawer.state.drawer);
    },
  },
};
</script>