import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../layout/main.vue'

import Cookie from '@/helper/cookie.js'

Vue.use(VueRouter)

const routes = [

  {
    // catch all 404 - define at the very end
    path: "*",
    name: 'notfound',
    component: () => import( /* webpackChunkName: "notfound" */ '../layout/notfound.vue')
  },

  {
    path: '/auth',
    name: 'auth',
    component: () => import( /* webpackChunkName: "auth" */ '../views/auth.vue')
  },

  {
    path: '/',
    name: 'main',
    component: Main,
    meta: {
      requiresAuth: true
    },
    children: [{
        path: '/dashboard',
        name: 'dashboard',
        component: () => import( /* webpackChunkName: "dashboard" */ '../views/dashboard.vue')
      },
      
      // UNOR
      {
        path: '/unor',
        name: 'unor',
        component: () => import( /* webpackChunkName: "unor" */ '../views/unor.vue')
      },

      // ANJAB
      {
        path: '/anjab/infojabatan',
        name: 'infojabatan',
        component: () => import( /* webpackChunkName: "infojabatan" */ '../views/anjab/infojabatan.vue')
      },
      {
        path: '/anjab/infojabatan/:id',
        name: 'viewij',
        component: () => import( /* webpackChunkName: "viewij" */ '../components/anjab/infojabatan/view.vue')
      },
      {
        path: '/anjab/infojabatan/detail/:id',
        name: 'detailij',
        component: () => import( /* webpackChunkName: "detailij" */ '../components/anjab/infojabatan/detail.vue')
      },
      {
        path: '/anjab/petajabatan',
        name: 'petajab',
        component: () => import( /* webpackChunkName: "petajabatan" */ '../views/anjab/petajabatan.vue')
      },
      {
        path: '/anjab/petajabatan/:id',
        name: 'petajab-view',
        component: () => import( /* webpackChunkName: "petajab-view" */ '../components/anjab/petajabatan/view.vue')
      },
      {
        path: '/anjab/petajabatan/detail/:id',
        name: 'petajab-detail',
        component: () => import( /* webpackChunkName: "petajab-detail" */ '../components/anjab/petajabatan/detail.vue')
      },
      {
        path: '/anjab/mutasi',
        name: 'mutasi',
        component: () => import( /* webpackChunkName: "mutasi" */ '../views/anjab/mutasi.vue')
      },
      {
        path: '/anjab/mutasi/:id',
        name: 'mutasi-detail',
        component: () => import( /* webpackChunkName: "mutasi-detail" */ '../components/anjab/mutasi/detail.vue')
      },

      // DATA JABATAN
      {
        path: '/jabatan/pimpinantinggi',
        name: 'jabatan-pimti',
        component: () => import( /* webpackChunkName: "jabatan-pimti" */ '../views/jabatan/pimpinantinggi.vue')
      },
      {
        path: '/jabatan/administrator',
        name: 'jabatan-adm',
        component: () => import( /* webpackChunkName: "jabatan-adm" */ '../views/jabatan/administrator.vue')
      },
      {
        path: '/jabatan/pengawas',
        name: 'jabatan-pengawas',
        component: () => import( /* webpackChunkName: "jabatan-pengawas" */ '../views/jabatan/pengawas.vue')
      },
      {
        path: '/jabatan/fungsional',
        name: 'jabatan-fung',
        component: () => import( /* webpackChunkName: "jabatan-fung" */ '../views/jabatan/fungsional.vue')
      },
      {
        path: '/jabatan/pelaksana',
        name: 'jabatan-pel',
        component: () => import( /* webpackChunkName: "jabatan-pel" */ '../views/jabatan/pelaksana.vue')
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  let myCookie = Cookie.get('myCookie');
  let token = Cookie.get('token');

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!myCookie || !token) {
      next({
        path: '/auth',
        params: {
          nextUrl: to.fullPath
        }
      })
    } else {
      const session = JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      let arr = session.authorities

      if (to.matched.some(record => record.meta.early_check)) {
        if (arr.length > 0) {
          next()
        } else {
          next({
            name: 'auth'
          })
        }
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router