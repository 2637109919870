<template>
  <v-app id="inspire">
    <CHeader />

    <CMenu />

    <v-main class="mt-5 pb-10" style="background-color: #edf2f9">
      <v-container>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import CHeader from "@/layout/header";
import CMenu from "@/layout/menu";

export default {
  components: {
    CHeader,
    CMenu,
  },

  data: () => ({}),

  created() {
    if (this.$route.path === "/") {
      this.$router.push("/dashboard").catch(() => {});
    }
  },
};
</script>