<template>
  <v-navigation-drawer v-model="drawer" color="background" app>
    <!--  -->
    <template v-slot:prepend>
      <v-list-item two-line>
        <v-list-item-avatar>
          <v-img src="@/assets/gambar/no_pic_male.png"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="caption">
            {{ session.nama }}
          </v-list-item-title>
          <v-list-item-subtitle class="caption">
            {{ session.nipBaru }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item link to="/dashboard" color="primary">
          <v-list-item-icon>
            <v-icon> mdi-view-dashboard </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/unor" color="primary">
          <v-list-item-icon>
            <v-icon> mdi-lan </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Unor</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group prepend-icon="mdi-folder" no-action>
          <template v-slot:activator>
            <v-list-item-title>Analisis Jabatan</v-list-item-title>
          </template>

          <v-list-item link to="/anjab/petajabatan">
            <v-list-item-title>Peta Jabatan</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/anjab/infojabatan">
            <v-list-item-title>Informasi Jabatan</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/anjab/mutasi">
            <v-list-item-title>Mutasi ASN</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group prepend-icon="mdi-briefcase-variant" no-action>
          <template v-slot:activator>
            <v-list-item-title>Kompetensi</v-list-item-title>
          </template>

          <v-list-item link to="/kompetensi/infojabatan">
            <v-list-item-title>SKM-PNS</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/kompetensi/petajabatan">
            <v-list-item-title>Kesenjangan Jabatan</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group prepend-icon="mdi-folder-file" no-action>
          <template v-slot:activator>
            <v-list-item-title>Laporan</v-list-item-title>
          </template>

          <v-list-item link to="/laporan/infojabatan">
            <v-list-item-title>Aparatur</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/laporan/petajabatan">
            <v-list-item-title>Jabatan</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-divider></v-divider>

        <v-list-group prepend-icon="mdi-briefcase-account" no-action>
          <template v-slot:activator>
            <v-list-item-title>Data Jabatan</v-list-item-title>
          </template>

          <v-list-item link to="/jabatan/pimpinantinggi">
            <v-list-item-title>Pimpinan Tinggi</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/jabatan/administrator">
            <v-list-item-title>Administrator</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/jabatan/pengawas">
            <v-list-item-title>Pengawas</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/jabatan/pelaksana">
            <v-list-item-title>Pelaksana</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/jabatan/fungsional">
            <v-list-item-title>Fungsional</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </template>

    <template v-slot:append>
      <div class="pa-2">
        <v-btn block small disabled> Versi : {{ appVersion }} </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import { version } from "../../package";

import Sdrawer from "@/store/drawer";

export default {
  computed: {
    drawer: {
      get() {
        return Sdrawer.state.drawer;
      },

      set(value) {
        Sdrawer.commit("toggle", value);
      },
    },
  },
  data: () => ({
    appVersion: version,
    token: Cookie.get("token"),
    session: "",
  }),

  async mounted() {
    this.session = JSON.parse(Cookie.dec(Cookie.get("myCookie")));
  },
};
</script>
